import { render, staticRenderFns } from "./CommodityAddress.vue?vue&type=template&id=3a849c70&scoped=true&"
import script from "./CommodityAddress.vue?vue&type=script&lang=js&"
export * from "./CommodityAddress.vue?vue&type=script&lang=js&"
import style0 from "./CommodityAddress.vue?vue&type=style&index=0&id=3a849c70&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a849c70",
  null
  
)

export default component.exports