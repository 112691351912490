<template>
	<div class="CommodityAddress">
		<w-navTab titleText="选择收货地址"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="CommodityAddress-body">
			<div :class="['middle', {'active' : active == index}]" v-for="(item, index) in addressList" @click="onSelect(index)">
				<div class="middle-top">
					<div>名称：{{ item.name }}</div>
					<div>电话：{{ item.telephone }}</div>
					<div>地址：{{item.province}}-{{item.city}}-{{item.area}}-{{item.detail}}</div>
				</div>
				<div class="middle-bottom">
					<div class="middle-bottom-left" @click.stop="selectDefault(index)">
						<van-checkbox v-model="item.status == defaultId" />
						<span>{{item.status == 2 ? '默认地址' : '设为默认'}}</span>
					</div>
					<div class="middle-bottom-right">
						<div @click.stop="updateAddress(index)">编辑</div>
						<div @click.stop="deleteAddress(index)">删除</div>
					</div>
				</div>
			</div>
			<div class="middle-btn" @click="$router.push({ name: 'CommodityAddressCreate' })">
				<van-icon name="add-o" size="24px" style="margin-right: 5px;" />
				添加新地址
			</div>
			<div class="footer-button">
				<van-button round block type="info" @click="onSubmit">保存</van-button>
			</div>
		</div>
	</div>
</template>

<script>
import Event from '@/store/event';
import VantVendor from '@/vendor/vant';
import Commodity from '@/api/commodity';
import LoadingPage from '@/components/LoadingPage';

import Business from '@/api/business';
export default {
	name: 'CommodityAddress',
	data() {
		return {
			active: 0,
			defaultId: 2,
			addressList: [],
			loadingPage: true
		};
	},
	created() {
		this.getList()
	},
	methods: {
		getList(){
			Commodity.productAddressIndex()
				.then(result => {
					this.addressList = result.data;
					this.loadingPage = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		onSelect(index) {
			this.active = index
		},
		updateAddress(index) {
			this.$router.push({
				name: 'CommodityAddressEdit',
				params: { id: this.addressList[index].id }
			});
		},
		deleteAddress(index) {
			VantVendor.Dialog.confirm({
				title: '删除提示',
				message: '您确认删除吗？'
			})
				.then(() => {
					Commodity.productAddressDelete({
						id: this.addressList[index].id
					})
						.then(result => {
							this.addressList.splice(index, 1);
							VantVendor.Toast.success('删除成功');
						})
						.catch(error => {
							VantVendor.Toast.fail(error.msg);
						});
				})
				.catch(() => {
					console.log('用户选择取消');
				});
		},
		// 设置默认地址
		selectDefault(index) {
			console.log(this.addressList[index].status)
			console.log(this.defaultId)
			console.log(this.addressList[index].status == this.defaultId)
			if(this.addressList[index].status == 2){
				return VantVendor.Toast('设置失败，当前地址为默认地址');
			}
			let params = {
				id: this.addressList[index].id
			};

			Commodity.productAddressSetDefault(params)
				.then(result => {
					// 切换至新的地址选中状态
					let list = this.addressList
					list.forEach(item=>{
						if(item.id == this.addressList[index].id){
							item.status = 2
						}else{
							item.status = 1
						}
					})
					this.addressList = list
					VantVendor.Toast.success('设置成功');
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		onSubmit(){
			console.log(this.addressList[this.active])
			Event.$emit('onCommodityAddress', this.addressList[this.active]);
			this.$router.back();
		}
	},
	components: {
		LoadingPage
	}
};
</script>

<style scoped lang="less">
.CommodityAddress {
	width: 100%;
	min-height: 100vh;
	background: #f2f2f2;
	.CommodityAddress-body{
		padding: 10px;
		padding-bottom: 80px;
		box-sizing: border-box;
		.middle {
			padding: 15px 10px;
			background-color: #fff;
			border-radius: 8px;
			margin-bottom: 10px;
			box-sizing: border-box;
			border: 2px solid #fff;
		
			&.active {
				border: 2px solid #3377ff;
			}
		
			.middle-top {
				font-size: 18px;
				line-height: 28px;
				color: #222;
				border-bottom: 1px solid #f2f2f2;
				padding-bottom: 10px;
			}
		
			.middle-bottom {
				font-size: 13px;
				line-height: 13px;
				color: #777;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 11px;
		
				.middle-bottom-left {
					display: flex;
					align-items: center;
		
					span {
						margin-left: 5px;
					}
				}
				.middle-bottom-right {
					display: flex;
					justify-content: flex-start;
					div {
						margin-left: 15px;
					}
				}
			}
		}
		
		.middle-btn {
			width: 100%;
			background: #ffffff;
			border-radius: 8px;
			font-size: 18px;
			color: #222;
			text-align: center;
			padding: 12px 0;
			margin-top: 11px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.footer-button {
			position: fixed;
			bottom: 20px;
			left: 0;
			width: 100%;
			padding: 0 10px;
			box-sizing: border-box;
			.van-button {
				width: 100%;
				font-size: 18px;
			}
		}
	}
	
}
</style>
